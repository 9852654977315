<template>
    <div>
        <v-container fluid class="px-0">
            <v-card outlined elevation="3">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :search="search"
                        :items="dataAirMancur"
                        :loading="loading"
                        dense
                    >
                        <template v-slot:top>
                            <v-toolbar flat dense class="mt-3 mb-3">
                                <v-row>
                                    <v-col md="9">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            autocomple="off"
                                            placeholder="Search..."
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3" align="right">
                                        <v-btn
                                            v-if="modify"
                                            color="#61B15A"
                                            class="text-subtitle-2 white--text"
                                            style="text-transform: unset !important"
                                            @click="uploadFile"
                                        >
                                            <v-icon left
                                                >mdi-plus-circle</v-icon
                                            >
                                            Terima Data
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.tgl="{ item }">
                            {{ item.tgl | moment("DD/MM/YYYY") }}
                        </template>

                        <template v-slot:item.mc="{ item }">
                            {{ getQtyStockBy("MC", item) }}
                        </template>
                        <template v-slot:item.ic="{ item }">
                            {{ getQtyStockBy("IC", item) }}
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                color="#61B15A"
                                small
                                class="text-caption white--text mr-2"
                                style="text-transform: unset !important"
                                @click.prevent="unduh(item.id, item.nomer_seri)"
                            >
                                Unduh CSV
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-container>

        <Upload
            :uploadFileDilaog="uploadFileDialog"
            :dataGudang="dataGudang"
            :materialCode="materialCode"
            :gudangCustomer="gudangCustomer"
            :gudangStock="gudangStock"
            :moving_state_id="moving_state_id"
            @closeUploadFileDialog="closeUploadFileDialog"
            @reloadUploadFile="reloadUploadFile"
        />

        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="white" rounded>
                <v-card-title class="justify-center text-h6 black--text">
                    {{ popTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import Upload from "../terimaDataAirMancur/upload.vue";

export default {
    components: { Upload },
    props: { modify: Boolean },
    data: () => ({
        uploadFileDialog: false,
        popDialog: false,
        popTitle: "",
        search: "",
        loading: false,
        headers: [
            {
                text: "Created Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "tgl",
            },
            {
                text: "Surat Jalan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "nomer_seri",
            },
            {
                text: "Quantity Btl/Slv/Box",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qty",
            },
            {
                text: "Quantity IC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "ic",
            },
            {
                text: "Quantity MC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "mc",
            },
            {
                text: "Action",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "actions",
            },
        ],
        dataAirMancur: [],
        dataGudang: [],
        materialCode: [],
        tipeGudangCustomer: "",
        tipeGudangStock: "",
        gudangCustomer: [],
        gudangStock: [],
        page: 1,
        itemsPerPage: 500,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
        moving_state_id: [],
        dataJsonToCsv: [],
        nomorSeri: "",
    }),

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        getQtyStockBy(satuan, item) {
            const divider = this.getItemStockDivider(
                satuan,
                item.mat_code,
                item.is_meta
            );

            const result = item.qty / divider;
            const commas = result % 1;

            return commas ? result.toFixed(2) : result;
        },

        getData() {
            this.loading = true;
            axios
                .get(
                    "v1/admin/surat_jalan/hasil_produksi?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page
                )
                .then((response) => {
                    this.loading = false;
                    this.dataAirMancur = response.data.data;
                    this.getGudang();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getData();
                        }
                    }
                });
        },
        async getGudang() {
            await axios
                .get("v1/user/gudang/list?limit=100")
                .then((response) => {
                    response.data.data.forEach((v) => {
                        if (v.is_activated === 1) {
                            this.dataGudang.push(v);
                        }
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getData();
                        }
                    }
                });

            this.gudangCustomer = [];
            await axios
                .get("v1/user/gudang_type/list?limit=100")
                .then((response) => {
                    response.data.data.forEach((e) => {
                        if (e.kode === "GUDANG_CUSTOMER") {
                            this.tipeGudangCustomer = e.id;
                        }
                    });
                });
            await axios
                .get("v1/user/gudang/list?limit=100")
                .then((response) => {
                    response.data.data.forEach((v) => {
                        if (v.is_activated === 1) {
                            if (this.tipeGudangCustomer === v.type_gudang_id) {
                                this.gudangCustomer.push(v);
                            }
                        }
                    });
                });

            this.gudangStock = [];
            await axios
                .get("v1/user/gudang_type/list?limit=100")
                .then((response) => {
                    response.data.data.forEach((e) => {
                        if (e.kode === "GUDANG_STOK") {
                            this.tipeGudangStock = e.id;
                        }
                    });
                });
            await axios
                .get("v1/user/gudang/list?limit=100")
                .then((response) => {
                    response.data.data.forEach((v) => {
                        if (v.is_activated === 1) {
                            if (this.tipeGudangStock === v.type_gudang_id) {
                                this.gudangStock.push(v);
                            }
                        }
                    });
                });

            this.materialCode = [];
            await axios
                .get("v1/user/material_code/list?limit=100")
                .then((response) => {
                    response.data.data.forEach((e) => {
                        this.materialCode.push(e);
                    });
                });
        },
        getTransType() {
            axios
                .get("v1/user/Scan/In")
                .then((response) => {
                    this.moving_state_id = response.data.data;
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getData();
                        }
                    }
                });
        },
        uploadFile() {
            this.getTransType();
            this.uploadFileDialog = true;
        },
        reloadUploadFile() {
            this.popTitle = "Data berhasil diupload";
            this.popDialog = true;
        },
        closeUploadFileDialog() {
            this.dataAirMancur = [];
            this.getData();
            this.uploadFileDialog = false;
        },
        closePopDialog() {
            this.popDialog = false;
            this.popTitle = "";
        },
        unduh(value, nmrSeri) {
            this.nomorSeri = nmrSeri;
            axios
                .get(
                    "v1/admin/master_data/list" +
                        "?limit=10000" +
                        "&page=1" +
                        "&sj_id=" +
                        value +
                        "&download=1"
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.downloadCSVData(response.data);
                    } else {
                        this.popTitle = response.data.errMessage;
                        this.popDialog = true;
                    }
                });
        },
        downloadCSVData(csv) {
            const anchor = document.createElement("a");
            anchor.href =
                "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
            anchor.target = "_blank";
            anchor.download =
                "File CSV Terima Data No." + this.nomorSeri + ".csv";
            anchor.click();
        },
    },

    created() {
        this.getData();
    },
};
</script>
