var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":"","elevation":"3"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"black--text",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.dataAirMancur,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mt-3 mb-3",attrs:{"flat":"","dense":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"9"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":"mdi-magnify","autocomple":"off","placeholder":"Search..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"md":"3","align":"right"}},[(_vm.modify)?_c('v-btn',{staticClass:"text-subtitle-2 white--text",staticStyle:{"text-transform":"unset !important"},attrs:{"color":"#61B15A"},on:{"click":_vm.uploadFile}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" Terima Data ")],1):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.tgl",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.tgl,"DD/MM/YYYY"))+" ")]}},{key:"item.mc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getQtyStockBy("MC", item))+" ")]}},{key:"item.ic",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getQtyStockBy("IC", item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-caption white--text mr-2",staticStyle:{"text-transform":"unset !important"},attrs:{"color":"#61B15A","small":""},on:{"click":function($event){$event.preventDefault();return _vm.unduh(item.id, item.nomer_seri)}}},[_vm._v(" Unduh CSV ")])]}}])})],1)],1)],1),_c('Upload',{attrs:{"uploadFileDilaog":_vm.uploadFileDialog,"dataGudang":_vm.dataGudang,"materialCode":_vm.materialCode,"gudangCustomer":_vm.gudangCustomer,"gudangStock":_vm.gudangStock,"moving_state_id":_vm.moving_state_id},on:{"closeUploadFileDialog":_vm.closeUploadFileDialog,"reloadUploadFile":_vm.reloadUploadFile}}),_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.popDialog),callback:function ($$v) {_vm.popDialog=$$v},expression:"popDialog"}},[_c('v-card',{attrs:{"color":"white","rounded":""}},[_c('v-card-title',{staticClass:"justify-center text-h6 black--text"},[_vm._v(" "+_vm._s(_vm.popTitle)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"light-green darken-1"},on:{"click":_vm.closePopDialog}},[_vm._v("Tutup")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }