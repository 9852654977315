<template>
    <div>
        <v-dialog v-model="uploadFileDilaog" persistent max-width="580">
            <v-card tile flat elevation="0">
                <v-card-title class="text-h6 font-weight-bold">
                    Terima Data
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pb-0 mt-3">
                    <v-form ref="form" v-model="valid">
                        <v-card-title
                            class="pa-0 text-subtitle-1 font-weight-bold black--text"
                        >
                            Jenis Penerimaan
                        </v-card-title>
                        <v-select
                            v-model="data.jenis"
                            dense
                            outlined
                            placeholder="Pilih Jenis Penerimaan"
                            :items="listPenerimaan"
                            item-text="text"
                            item-value="id"
                            :rules="[rules.required]"
                        >
                        </v-select>

                        <v-container class="pa-0" v-if="data.jenis === '1'">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Referal Number
                            </v-card-title>
                            <v-text-field
                                v-model="data.refNumber"
                                dense
                                outlined
                                placeholder="Masukkan no. referal"
                                :rules="[rules.required]"
                                autocomplete="off"
                            >
                            </v-text-field>
                        </v-container>

                        <v-container class="pa-0" v-if="data.jenis === '2'">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Referal Number
                            </v-card-title>
                            <v-text-field
                                v-model="data.refNumber"
                                dense
                                outlined
                                placeholder="Masukkan no. referal"
                                :rules="[rules.required]"
                                autocomplete="off"
                            >
                            </v-text-field>

                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Surat Jalan
                            </v-card-title>
                            <v-text-field
                                v-model="data.suratJalan"
                                dense
                                outlined
                                placeholder="Masukkan no. surat jalan"
                                :rules="[rules.required]"
                                autocomplete="off"
                            >
                            </v-text-field>

                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Pilih Gudang Tujuan
                            </v-card-title>
                            <v-select
                                v-model="data.gudang"
                                dense
                                outlined
                                placeholder="Pilih Gudang Tujuan"
                                :items="dataGudang"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.required]"
                            >
                            </v-select>
                        </v-container>

                        <v-container class="pa-0" v-if="data.jenis === '3'">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Referal Number
                            </v-card-title>
                            <v-text-field
                                v-model="data.refNumber"
                                dense
                                outlined
                                placeholder="Masukkan no. referal"
                                :rules="[rules.required]"
                                autocomplete="off"
                            >
                            </v-text-field>

                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Surat Jalan
                            </v-card-title>
                            <v-text-field
                                v-model="data.suratJalan"
                                dense
                                outlined
                                placeholder="Masukkan no. surat jalan"
                                :rules="[rules.required]"
                                autocomplete="off"
                            >
                            </v-text-field>

                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Nomor DO Sales
                            </v-card-title>
                            <v-text-field
                                v-model="data.noSales"
                                dense
                                outlined
                                placeholder="Masukkan no. DO Sales"
                                :rules="[rules.required]"
                                autocomplete="off"
                            >
                            </v-text-field>

                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Pilih Gudang Tujuan
                            </v-card-title>
                            <v-select
                                v-model="data.gudang"
                                dense
                                outlined
                                placeholder="Pilih Gudang Tujuan"
                                :items="gudangCustomer"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.required]"
                            >
                            </v-select>
                        </v-container>

                        <v-container class="pa-0" v-if="data.jenis === '4'">
                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Referal Number
                            </v-card-title>
                            <v-text-field
                                v-model="data.refNumber"
                                dense
                                outlined
                                placeholder="Masukkan no. referal"
                                :rules="[rules.required]"
                                autocomplete="off"
                            >
                            </v-text-field>

                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Surat Jalan
                            </v-card-title>
                            <v-text-field
                                v-model="data.suratJalan"
                                dense
                                outlined
                                placeholder="Masukkan no. surat jalan"
                                :rules="[rules.required]"
                                autocomplete="off"
                            >
                            </v-text-field>

                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Pilih Gudang Tujuan
                            </v-card-title>
                            <v-select
                                v-model="data.gudang"
                                dense
                                outlined
                                placeholder="Pilih Gudang Tujuan"
                                :items="gudangStock"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.required]"
                            >
                            </v-select>

                            <v-card-title
                                class="pa-0 text-subtitle-1 font-weight-bold black--text"
                            >
                                Material
                            </v-card-title>
                            <v-select
                                v-model="data.matcode"
                                dense
                                outlined
                                placeholder="Pilih Material"
                                :items="materialCode"
                                item-text="mat_desc"
                                item-value="mat_code"
                                :rules="[rules.required]"
                            >
                            </v-select>
                        </v-container>

                        <div
                            class="rounded-lg"
                            style="border: 2px dashed #7a7a7a"
                            @dragover.prevent
                            @drop.prevent
                        >
                            <div @drop="dragFile">
                                <v-card-title
                                    class="
                    justify-center
                    text-subtitle-1
                    font-weight-bold
                    black--text
                  "
                                >
                                    Upload File .CSV
                                </v-card-title>
                                <center>
                                    <label class="custom-file-upload">
                                        <input
                                            type="file"
                                            @change="uploadFile"
                                            accept="text/csv"
                                        />
                                        Pilih File
                                    </label>
                                </center>
                                <v-card-title
                                    class="justify-center text-caption"
                                >
                                    Silahkan drag dan drop file yang ingin kamu
                                    upload
                                </v-card-title>
                                <div v-if="data.file.length">
                                    <v-card-title
                                        class="justify-center pt-0 text-caption"
                                        v-for="thisFile in data.file"
                                        :key="thisFile.length"
                                    >
                                        File: {{ thisFile.name }}
                                    </v-card-title>
                                </div>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="closeUploadFileDialog"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="#61B15A"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="upload"
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Tambahkan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="red" rounded>
                <v-card-title class="text-h6 white--text">
                    Data Gagal Ditambahkan !!
                </v-card-title>
                <v-card-title class="text-caption white--text">
                    {{ popTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        uploadFileDilaog: Boolean,
        dataGudang: Array,
        gudangCustomer: Array,
        gudangStock: Array,
        moving_state_id: Array,
        materialCode: Array,
    },
    data: () => ({
        valid: false,
        loading: false,
        popDialog: false,
        popTitle: "",
        listPenerimaan: [
            { id: "1", text: "Hasil produksi" },
            { id: "2", text: "Antar Gudang" },
            { id: "3", text: "Sales" },
            { id: "4", text: "Hasil Produksi Auto Terima" },
        ],
        rules: {
            required: (value) => !!value || "Tidak boleh kosong !!",
        },
        data: {
            jenis: "",
            refNumber: "",
            suratJalan: "",
            noSales: "",
            gudang: "",
            file: "",
            moveState: "",
            matcode: "",
        },
    }),
    methods: {
        upload() {
            this.loading = true;
            if (this.data.file === "") {
                this.popDialog = true;
                this.popTitle = "File tidak boleh kosong";
            }
            if (this.data.jenis === "1") {
                axios.get("v1/user/Scan/In").then((response) => {
                    response.data.data.forEach((e) => {
                        if (e.inisial === "HASIL_PRODUKSI") {
                            this.moveState = e.id;
                        }
                    });
                    this.terimaHasilProduksi();
                });
            } else if (this.data.jenis === "2") {
                axios.get("v1/user/Scan/Out").then((response) => {
                    response.data.data.forEach((f) => {
                        if (f.inisial === "ANTAR_GUDANG") {
                            this.moveState = f.id;
                        }
                    });
                    this.terimaAntarGudang();
                });
            } else if (this.data.jenis === "3") {
                axios.get("v1/user/Scan/Out").then((response) => {
                    response.data.data.forEach((g) => {
                        if (g.inisial === "SALES") {
                            this.moveState = g.id;
                        }
                    });
                    this.terimaSales();
                });
            } else if (this.data.jenis === "4") {
                axios.get("v1/user/Scan/In").then((response) => {
                    response.data.data.forEach((e) => {
                        if (e.inisial === "HASIL_PRODUKSI") {
                            this.moveState = e.id;
                        }
                    });
                    this.terimaHasilProduksiAutoTerima();
                });
            }
        },
        terimaHasilProduksi() {
            const data = new FormData();
            data.append("file", this.data.file[0]);
            data.append("ref_no", this.data.refNumber);
            data.append("move_type_id", this.moveState);

            axios
                .post("v1/admin/terima_data", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.loading = false;
                        this.$emit("reloadUploadFile");
                        this.closeUploadFileDialog();
                    }
                })
                .catch((error) => {
                    this.popDialog = true;
                    this.popTitle = error.response.data.errMessage;
                });
        },
        terimaAntarGudang() {
            var no_do_sales = "";
            const data = new FormData();
            data.append("file", this.data.file[0]);
            data.append("ref_no", this.data.refNumber);
            data.append("no_surat_jalan", this.data.suratJalan);
            data.append("no_do_sales", no_do_sales);
            data.append("gudang_id", this.data.gudang);
            data.append("move_type_id", this.moveState);

            axios
                .post("v1/admin/auto_terima/gudang", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.loading = false;
                        this.$emit("reloadUploadFile");
                        this.closeUploadFileDialog();
                    }
                })
                .catch((error) => {
                    this.popDialog = true;
                    this.popTitle = error.response.data.errMessage;
                });
        },
        terimaSales() {
            console.log(this.moveState);
            const data = new FormData();
            data.append("file", this.data.file[0]);
            data.append("ref_no", this.data.refNumber);
            data.append("no_surat_jalan", this.data.suratJalan);
            data.append("no_do_sales", this.noSales);
            data.append("gudang_id", this.data.gudang);
            data.append("move_type_id", this.moveState);

            axios
                .post("v1/admin/auto_terima/sales", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.loading = false;
                        this.$emit("reloadUploadFile");
                        this.closeUploadFileDialog();
                    }
                })
                .catch((error) => {
                    this.popDialog = true;
                    this.popTitle = error.response.data.errMessage;
                });
        },
        terimaHasilProduksiAutoTerima() {
            var no_do_sales = "";
            const data = new FormData();
            data.append("file", this.data.file[0]);
            data.append("ref_no", this.data.refNumber);
            data.append("no_surat_jalan", this.data.suratJalan);
            data.append("no_do_sales", no_do_sales);
            data.append("gudang_id", this.data.gudang);
            data.append("move_type_id", this.moveState);
            data.append("mat_code", this.data.matcode);

            axios
                .post("v1/admin/auto_terima/hasil_produksi", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.loading = false;
                        this.$emit("reloadUploadFile");
                        this.closeUploadFileDialog();
                    }
                })
                .catch((error) => {
                    this.popDialog = true;
                    this.popTitle = error.response.data.errMessage;
                });
        },
        uploadFile(e) {
            this.data.file = e.target.files;
        },
        dragFile(e) {
            this.data.file = e.dataTransfer.files;
        },
        closeUploadFileDialog() {
            this.loading = false;
            this.data.file = "";
            this.$refs.form.reset();
            this.$emit("closeUploadFileDialog");
        },
        closePopDialog() {
            this.loading = false;
            this.popDialog = false;
        },
    },
};
</script>

<style scoped>
input[type="file"] {
    display: none;
}
.custom-file-upload {
    background: #61b15a;
    color: #ffffff;
    box-shadow: 0px 2px 5px rgba(34, 34, 34, 0.1);
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
</style>
